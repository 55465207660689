import * as React from 'react';
import { PublicCosting } from '../../templates/PublicCosting/PublicCosting';
import { Router } from '@reach/router';
import { RouterComponent } from '../../components/RouterComponent/RouterComponent';
// import * as styles from './costing.module.scss'

export interface ICostingProps{

}

export default function Costing(){
    return(
        <Router basepath="/response" component={ ({children}) => <RouterComponent children={children} /> }>
            <PublicCosting path=":responseId" />
        </Router>
    )
}